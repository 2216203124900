import React from 'react'
import styled from 'styled-components'

const testimonials = [
  {
    author: 'Pieter de Lange',
    comment: 'Great fun for functions.',
    rating: '4'
  },
  {
    author: 'Nicola Visser',
    comment: 'A fantastic kids party venue! Activites are so well organized, clean facilities, a sparkling pool and friendly staff. We had a great party, thank you!',
    rating: '5'
  },
  {
    author: 'Alicia Cilliers',
    comment: 'Rustic, excellent for children birthday party lapa area recommended with pool when booking',
    rating: '5'
  }
]

// ---STYLES---

const Testimonials = () => {
  return (
    <TestimonialsContainer id="testimonials">
      <h1>Testimonials</h1>
      <small className="leave-review"><a href="https://g.page/AdventureNBeyond/review?gm" target="_blank" rel="noreferrer">Leave a review</a></small>
      <TestimonialsCardsContainer>
        {testimonials.map(({author, comment, rating}, index) => (
          <TestimonialCard key={index}>
            <p>{comment}</p>
            <small>{rating} stars</small>
            <strong>~ {author}</strong>
          </TestimonialCard>
        ))}
      </TestimonialsCardsContainer>
    </TestimonialsContainer>
  )
}

const TestimonialsContainer = styled.section`
  margin-top: 3rem;

  h1 {
    color: #fff;
    font-size: 4rem;
    text-align: center;
  }

  .leave-review {
    display: block;
    margin-top: 0.6rem;
    text-align: center;

    a { 
      color: #fff;
      background: var(--primary-color);
      padding: 0.4rem 1rem;
      border-radius: 6px;
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 576px) {
    h1 {
      font-size: 3rem;
    }
  }
`

const TestimonialsCardsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 3rem 0;

  @media (max-width: 576px) {
    flex-direction: column;
    /* -webkit-gap: 2rem;
    gap: 2rem; */
    padding: 0 1rem;
  }
`

const TestimonialCard = styled.div`
  display: inline-block;
  background: #f4f4f4;
  padding: 2rem 2rem 3.5rem 2rem;
  /* margin: 1rem; */
  max-width: 30rem;
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 30% 80%, 10% 100%, 10% 80%, 0 80%);

  p {
    margin-bottom: 1rem;
  }

  strong {
    display: block;
  }

  @media (max-width: 576px) {
    margin: 1rem 0;
  }
`

export default Testimonials
