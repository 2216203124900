import React from 'react'
import styled from 'styled-components'
import PricingCard from '../components/PricingCard'

const PricingCardsSection = () => {
  return (
    <PricingCardsSectionContainer id="pricing">
      <CardContainer>
        <PricingCard title="Adventurer" price="140" activityCount="2" />
        <PricingCard title="Indiana Jones" price="210" activityCount="3" />
        <PricingCard title="Paintballer" price="120" activityCount="1" hourEstimate="1.5" >
          <li>Rental Equipment (Marker, Mask, Loader, Overall</li>
          <li>100 paintballs per player</li>
        </PricingCard>
      </CardContainer>
    </PricingCardsSectionContainer>
  )
}

// ---STYLES---

const PricingCardsSectionContainer = styled.section`
  background: #333;
  padding: 7rem 0;

  @media (max-width: 576px) {
    padding: 4rem 1rem;
  }
`

const CardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: 1400px;
  margin: auto;

  & > *:nth-of-type(1) { transform: scale(1.2); }
  & > *:nth-of-type(2) { order: -1; }

  @media (max-width: 576px) {
    /* display: initial; */
    flex-direction: column;
    /* -webkit-gap: 1.5rem; */
    /* gap: 1.5rem; */

    & > * { margin: 0.75rem 0; }
    & > *:nth-of-type(1) { transform: initial; }
    /* & > *:nth-of-type(2) { order: initial; } */
  }
`

export default PricingCardsSection
