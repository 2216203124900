import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import BookingContext from '../context/BookingContext'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const initialFormData = {
  name: '',
  tel: '',
  email: '',
  package: '',
  participants: 0,
  nonParticipants: 0,
  date: '',
  time: ''
}

// COMPONENT
const QuickBookForm = () => {
  const [formData, setFormData] = useState(initialFormData)
  const { packageOption } = useContext(BookingContext)
  const popUp = useRef(null)

  const addClassOnBlur = (e) => {
    e.target.classList.add('touched')
  }

  // Update formData state
  const handleFormInput = (e) => {
    const inputField = e.target
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        [inputField.name]: inputField.value
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formData
      })
    })
      .then(() => {
        popUp.current.classList.add('pop-up');
        setFormData(initialFormData)
        for (const i of form) {
          i.classList.remove('touched')
        }
      })
      .catch((error) => alert(error))
  }

  // Update package (Indiana Jones, Adventurer, etc.) through context
  useEffect(() => {
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        package: packageOption
      }
    })
  }, [packageOption])

  return (
    <FormContainer id="qbform">
      <h1>Quick Book Now</h1>
      {/* 
        Name          | Mobile
        E-mail
        Select Package 
        Activities (Limited to package type)
        Participants | Non-participants (R20)
        Lapa/Swimming Pool (R500)
        Date         | Time
      */}
      <form 
        name="booking" 
        method="post" 
        data-netlify="true" 
        data-netlify-honeypot="bot-field" 
        onSubmit={handleSubmit}
        // noValidate
      >
        <input type="hidden" name="form-name" value="booking" />
        <div className="content">
          <label style={{gridArea: "name"}} >
            Name
            <input 
              type="text" 
              name="name" 
              id="" 
              value={formData.name} 
              onChange={handleFormInput} 
              onBlur={addClassOnBlur} 
              required 
            />
          </label>
          <label style={{gridArea: "mobile"}} >
            Mobile Number
            <input 
              type="tel" 
              name="tel" 
              id="" 
              value={formData.tel} 
              onChange={handleFormInput} 
              onBlur={addClassOnBlur} 
              required 
            />
          </label>
          <label style={{gridArea: "email"}}>
            Email
            <input 
              type="email" 
              name="email" 
              id="" 
              value={formData.email} 
              onChange={handleFormInput} 
              onBlur={addClassOnBlur} 
              required 
            />
          </label>
          <label style={{gridArea: "package"}}>
            Package
            <select 
              name="package" 
              id="" 
              value={formData.package} 
              onChange={handleFormInput} 
              onBlur={addClassOnBlur} 
              required
            >
              <option value="">--Please choose an option--</option>
              <option value="adventurer">Adventurer (2 Activities) | R140pp</option>
              <option value="indiana-jones">Indiana Jones (3 Activities) | R210pp</option>
              <option value="paintballer">Paintballer (Paintball Only) | R120pp</option>
              <option value="custom">Custom</option>
            </select>
          </label>
          <label style={{gridArea: "participants"}}>
            Participants
            <input 
              type="number" 
              min="0" 
              max="50" 
              name="participants" 
              id="" 
              value={formData.participants} 
              onChange={handleFormInput} 
              onBlur={addClassOnBlur} 
            />
          </label>
          <label style={{gridArea: "non-participants"}}>
            Non-Participants
            <input 
              type="number" 
              min="0" 
              max="50" 
              name="nonParticipants" 
              id="" 
              value={formData.nonParticipants} 
              onChange={handleFormInput} 
              onBlur={addClassOnBlur} 
            />
          </label>
          <label style={{gridArea: "date"}} >
            Date
            <input 
              type="date" 
              name="date" 
              id="" 
              value={formData.date} 
              onChange={handleFormInput} 
              onBlur={addClassOnBlur} 
              required 
            />
          </label>
          <label style={{gridArea: "time"}} >
            Time
            <input 
              type="time" 
              name="time" 
              id="" 
              value={formData.time} 
              onChange={handleFormInput} 
              onBlur={addClassOnBlur} 
              required 
            />
          </label>
        </div>

        <button type="submit">Continue</button>
      </form>

      {/* Submit conformation pop-up */}
      <div className="submit-conformation" ref={popUp}>
        <p>
          <button onClick={() => popUp.current.classList.remove('pop-up')}>X</button>
          <br/>
          Thank you for using our <strong>Quick Book Form</strong>! We'll get back to you to confirm your booking.
        </p>
      </div>
    </FormContainer>
  )
}

// --- STYLES ---
const FormContainer = styled.section`
  --form-border-radius: 15px;
  background: rgba(80, 80, 80, 0.7);
  backdrop-filter: blur(4px);
  color: #fff;
  width: 30rem;
  height: 35rem;
  padding-top: 2rem;
  border-radius: var(--form-border-radius);
  position: relative;

  /* Closed bookings overlay */
  &:after {
    content: 'Sorry, we are temporarily closed due to the severe impact of COVID on the business and currently not accepting any new bookings.';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--primary-color);
    opacity: 0.95;
    padding: 2rem;
    border-radius: var(--form-border-radius);
    font-size: 1.1rem;
    line-height: 1.5;
    text-align: center;
  }

  h1 {
    font-size: 2rem;
    text-decoration: underline;
    text-align: center;
  }

  form {
    div.content {
      display: grid;
      grid-template-areas: "name mobile"
                            "email email"
                            "package package"
                            "participants non-participants"
                            "date time";
      grid-gap: 1rem;
      padding: 2rem;
    }

    label {
      display: block;

      input, select {
        display: block;
        width: 100%;
        padding: 0.4rem;
        margin-top: 0.2rem;
        font-size: 1rem;
        border: none;
        border-radius: 5px;

        &.touched:invalid {
          border: solid 2px red;
        }
      }

      &.error {
        input, select { border: solid 2px red; }
        small { color: red; }
      }
    }
  }

  button[type='submit'] {
    --webkit-appearance: none;
    display: block;
    position: absolute;
    bottom: 0;
    background: var(--primary-color);
    color: #fff;
    border: none;
    width: 100%;
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 0 0 var(--form-border-radius) var(--form-border-radius);
    cursor: pointer;
  }

  .submit-conformation {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    color: #000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.7);
    border-radius: var(--form-border-radius);

    p {
      position: relative;
      background: #f4f4f4;
      width: 80%;
      padding: 2rem;
      border-radius: var(--form-border-radius);
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      background: #f00;
      color: #fff;
      width: 25px;
      height: 25px;
      line-height: 25px;
      margin: 7px;
      border: none;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .pop-up {
    display: flex;
    transition: display 1s;
  }

  button:focus, input[type='submit']:focus {
    outline: none;
    border: none;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    width: 100%;
    height: initial;

    form {
      padding-bottom: 4rem;
      
      div.content {
        grid-template-areas: "name name"
                              "mobile mobile"
                              "email email"
                              "package package"
                              "participants participants"
                              "non-participants non-participants"
                              "date time";
        grid-gap: 1rem;
        padding: 1rem;
      }
    }
  }
`

export default QuickBookForm