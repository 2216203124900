import React, { useState, useEffect, useRef } from "react"
import styled from 'styled-components'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Showcase from '../components/Showcase'
import PricingCardsSection from '../components/PricingCardsSection'
import VerticalGallery from '../components/VerticalGallery'
import Testimonials from '../components/Testimonials'

const showcaseOptions = {
  rootMargin: "-95% 0% 0% 0%",
  // threshold: [0.95],
}

export default function Home() {
  const [navScrolled, setNavScrolled] = useState(false)
  const showcaseRef = useRef(null)
  
  const showcaseObserver = useRef(null)

  useEffect(() => {
    if (showcaseObserver.current) showcaseObserver.current.disconnect()

    showcaseObserver.current = new IntersectionObserver((entries, showcaseObserver) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) { 
          setNavScrolled(true) 
        } else {
          setNavScrolled(false)
        }
      })
    }, showcaseOptions)
    
    const { current: currentObserver } = showcaseObserver
    
    if (showcaseRef) currentObserver.observe(showcaseRef.current)

    return () => currentObserver.disconnect()
  }, [showcaseRef])
  
  return (
    <Layout navPosition='fixed' navScrolled={navScrolled} >
      <Seo title='Home' />
      <Showcase ref={showcaseRef} />
      <P>
      Looking for something different and adventurous for your child’s upcoming birthday party, teambuilding or a family / group outing? Why not contact <span style={{color: 'var(--primary-color)'}}>Adventure 'n' Beyond</span> for an unforgettable outdoor event with a variety of activities to choose from in a safe environment.
      </P>
      <VerticalGallery/>
      {/* <ServicesGrid/> */}
      <PricingCardsSection/>
      <P.Small>
      Participating groups are welcome to braai or bring their own food/drinks. <br/>
      Braai facilities are available on request <br/>
      Groups are welcome to book the Thatched Lapa, small kitchen and swimming pool at an additional cost.
      </P.Small>
      <Testimonials/>
    </Layout>
  )
}

// --- STYLES ---
const P = styled.p`
  font-size: 2rem;
  color: #fff;
  max-width: 1200px;
  margin: auto;
  padding: 8rem 0;
  text-align: center;
  line-height: 1.4;

  @media (max-width: 576px) {
    font-size: 1.6rem;
    margin: 6rem 1rem;
    padding: 0;
  }
`

P.Small = styled(P)`
  font-size: 0.9rem;
  font-weight: 100;
  padding: 0 1rem;
`