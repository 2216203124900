import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

const VerticalGallery = () => {

  const data = useStaticQuery(graphql`query MyQuery {
  allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
    nodes {
      publicURL
      childImageSharp {
        original {
          width
          height
        }
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
}
`)

  const images = data.allFile.nodes
  const galContainer = useRef(null);
  const [imagesCount, setImagesCount] = useState(0)
  const [imagesLoaded, setImagesLoaded] = useState(0)

  const handleOnImageLoad = () => {
    setImagesLoaded(prevImagesLoaded => prevImagesLoaded + 1)
  }
  
  useEffect(() => {
    setImagesCount(galContainer.current.children.length)
    galContainer.current.innerHTML += galContainer.current.innerHTML

    // AUTOMATED SCROLL
    const interval = setInterval(() => {
      galContainer.current.scrollLeft += 1
    }, 15);
    return () => clearInterval(interval);
  }, [])

  // ALL IMAGES LOADED
  useEffect(() => {
    if (imagesLoaded === imagesCount) {

      // GET WIDTH OF TOTAL CHILDREN
      const galChildren = Array.from(galContainer.current.children)
      let childrenWidth = 0;
      galChildren.forEach(image => {
        childrenWidth += image.width
      })

      // ADD DUPLICATES
      // if (childrenWidth < galContainer.current.width) {
      //   console.log(childrenWidth, galContainer.current.width)
      // }

      // HANDLE SCROLL
      galContainer.current.onscroll = (e) => {
        // if ((e.target.scrollLeft + e.target.clientWidth) >= e.target.scrollWidth)
        // if (e.target.scrollLeft - 1 >= childrenWidth)
        if (e.target.scrollLeft - 2 >= childrenWidth / 2) {
          e.target.scrollLeft = 2
        } else if (e.target.scrollLeft < 2) {
          e.target.scrollLeft = childrenWidth / 2
        }
      }
    }
  }, [imagesLoaded, imagesCount]) // Will get warning bc. 'imagesCount' is not added to dependency array.

  return (<>
    <GalleryContainer ref={galContainer} id='gallery' >
      {/* <img src="https://source.unsplash.com/featured/?nature" alt="" onLoad={handleOnImageLoad} />
      <img src="https://source.unsplash.com/featured/?water" alt="" onLoad={handleOnImageLoad} />
      <img src="https://source.unsplash.com/featured/?person" alt="" onLoad={handleOnImageLoad} />
      <img src="https://source.unsplash.com/featured/?adventure" alt="" onLoad={handleOnImageLoad} />
      <img src="https://source.unsplash.com/featured/?tree" alt="" onLoad={handleOnImageLoad} />
      <img src="https://source.unsplash.com/featured/?happy" alt="" onLoad={handleOnImageLoad} />
      <img src="https://source.unsplash.com/featured/?friends" alt="" onLoad={handleOnImageLoad} /> */}
      {images.map((img, index) => (
        <img key={index} src={img.publicURL} alt="" onLoad={handleOnImageLoad} />
      ))}
    </GalleryContainer>
    {/* <div style={{height: '20rem', overflowX: 'scroll'}}>
      {images.map((img, index) => {
        const imgWidth = img.childImageSharp.original.width
        const imgHeight = img.childImageSharp.original.height
        const imageAspectRatio = imgWidth / imgHeight

        console.log(imageAspectRatio, 320 * imageAspectRatio)
        return (
          <Img 
            key={index} 
            fluid={img.childImageSharp.fluid} 
            onLoad={handleOnImageLoad} 
            style={{ ...gatsbyImageContainerStyle, height: 320, width: `${320 * imageAspectRatio}`,}} 
            imgStyle={gatsbyImageStyle} 
          />
        )
      })}
    </div> */}
  </>)
}

// ---STYLES---

const GalleryContainer = styled.div`
  background: #aaa;
  display: flex;
  height: 20rem;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  img {
    height: 100%;
    object-fit: contain;
  }
`

// const gatsbyImageContainerStyle = {
//   display: 'inline-block',
//   // height: '100%',
//   width: 600
// }

// const gatsbyImageStyle = {
//   objectFit: 'contain'
// }

export default VerticalGallery

// https://codepen.io/vincentorback/pen/zxRyzj