import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image";
import TextLoop from 'react-text-loop'
import QuickBookForm from './QuickBookForm'

// import forestPath from '../images/forest-path.jpg'
import anbEntrance from '../images/IMG_1076.png'

const Showcase = React.forwardRef((props, ref) => {
  const bgImage = useStaticQuery(graphql`query BackgroundImageQuery {
  allFile(filter: {base: {eq: "IMG_1076.png"}}) {
    nodes {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
}
`).allFile.nodes[0]

  return (
    <ShowcaseContainer ref={ref}>
      <GatsbyImage image={bgImage.childImageSharp.gatsbyImageData} alt="" style={imgStyles} />
      <h1 className='text-loop'>
        <TextLoop interval='2000'>
          <span>Archery...</span>
          <span>Wall <br/>Climbing...</span>
          <span>Giant <br/>Swing...</span>
          <span>Paintball...</span>
          <span>and <br/>Beyond!</span>
        </TextLoop>
      </h1>
      <QuickBookForm/>
    </ShowcaseContainer>
  );
})

const imgStyles = {
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: '-1',
}

// const StyledImg = styled(Img)`
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   z-index: -1;
// `

const ShowcaseContainer = styled.section`
  position: relative;
  background: rgba(0,0,0,0.4);
  background: linear-gradient(0deg, rgba(51,51,51,1) 0%, rgba(0,0,0,0.25) 7.5%);
  height: 97vh;
  min-height: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 10rem 0 10rem;

  // BACKGROUND IMAGE
  /* &:before {
    content: '';
    background: url(${anbEntrance}) no-repeat center center/cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  } */

  .text-loop {
    font-size: 6rem;
    color: #fff;
    width: 200px;
    word-wrap: break-word;

    span {
      width: 200px;
      word-wrap: break-word;

      @media (min-width: 1500px) {
        br {display: none;}
      }
    }
  }

  @media (max-width: 576px) {
    display: block;
    padding: 1rem 1rem 4rem 1rem !important;
    height: initial;

    .text-loop {
      font-size: 4rem;
      height: 10rem;
      margin: 8.5rem 0 4rem 0;
    }
  }

  @media (max-width: 1500px) {
    padding: 5rem 7rem 0 7rem;
  }

`



export default Showcase