import React, { useContext } from 'react'
import styled from 'styled-components'
import BookingContext from '../context/BookingContext'

// UTILITIES
const scrollTo = (element) => {
  const scrollTop = document.querySelector(element).offsetTop
  window.scroll({
    top: scrollTop - 50,
    behavior: "smooth"
  });
}

// COMPONENT
const PricingCard = ({title, price, activityCount, hourEstimate=activityCount, children}) => {
  const { setPackageOption } = useContext(BookingContext)

  // When 'continue' is clicked on card
  const goToForm = () => {
    scrollTo('#qbform')
    setPackageOption(title.toLowerCase().replace(' ', '-'))
  }
  
  return (
    <PricingCardContainer>
      <h2>{title}</h2>
      <h3>R<span>{price}</span>pp</h3>
  <h4>{activityCount} ACTIVIT{!(activityCount === '1') ? 'IES' : 'Y'}</h4>
      <small>±{hourEstimate} hours</small>
      <h5>Choose from:</h5>
      <ul>
        {
          children 
          ? children
          : <>
            <li>Paintball (ages 10+)</li>
            <li>Air Riflery (ages 10+)</li>
            <li>Giant Swing (ages 10+)</li>
            <li>Climbing Wall (6m) (ages 5+)</li>
            <li>Splatmaster (low impact paintball) (ages 5+)</li>
            <li>Water Guns (ages 5+)</li>
            <li>Archery (ages 5+)</li>
          </>
        }
      </ul>
      <button onClick={goToForm} >Book Now</button>
    </PricingCardContainer>
  )
}

// ---STYLES---
const PricingCardContainer = styled.div`
  display: flex;
  flex-flow: column;
  background: #ccc;
  width: 350px;
  margin: 1rem;
  text-align: center;
  border-radius: 20px;

  h2 {
    font-size: 1rem;
    padding-top: 1rem;
  }

  h3 {
    font-size: 3rem;
    margin: 2rem 0;

    span {
      font-size: 4rem;
    }
  }

  h4 { font-size: 1.17rem; }

  small {}

  h5 {
    background: #6f6f6f;
    color: #fff;
    padding: 0.5rem 0;
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }

  ul {
    background: #f4f4f4;
    padding: 2rem;
    list-style: none;
    flex-grow: 1;

    li {
      padding: 7px 0;
      border-bottom: solid 1px #ccc;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  button {
    display: block;
    background: var(--primary-color);
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 0 0 20px 20px;
    cursor: pointer;
  }

  button:focus, input[type='submit']:focus {
    outline: none;
    border: none;
  }

  @media (max-width: 576px) {
    width: 100%;
    margin: 0;
  }
`

export default PricingCard
